import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnDestroy, OnInit, Input, AfterViewInit } from '@angular/core';
import { NbDialogService, NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HeaderService } from 'app/services/header.service';
import { MENU_ITEMS } from 'app/pages/pages-menu';
import { ProfileDataService } from 'app/services/profileData/profile-data-service.service';
import { ProfileComponent } from 'app/pages/profile/profile.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { ChangePasswordComponent } from 'app/pages/change-password/change-password.component';
import { UserModel } from 'app/model/user.model';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() position = 'normal';
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  tempselecteditem: any;
  menu: NbMenuItem[] = [];
  userMenu = [{ title: 'Edit Profile' }, { title: 'Change Password' }, { title: 'Log out' }];
  selectedItemNgModel: any;
  selectedItem: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    }

  ];

  currentTheme = 'default';

  constructor(private sidebarService: NbSidebarService, private _header: HeaderService,
    private menuService: NbMenuService, private _profileDataService: ProfileDataService,
    private themeService: NbThemeService,
    private userService: UserData, public translate: TranslateService,
    private layoutService: LayoutService, private dialogService: NbDialogService,
    private router: Router, private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private breakpointService: NbMediaBreakpointsService) {
    this._header.setcallBit = true;
    this.menu = MENU_ITEMS;
    this.tempselecteditem = [];

  }

  ngOnInit() {
    this.setUser();
    this.setLanguage();
    this.currentTheme = this.themeService.currentTheme;
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.menuService.onItemClick().subscribe(res => {
      this.selectedItem = res.item.title;
      this.tempselecteditem = res;
    });
    this.onItemSelected();
  }


  async setUser() {
    let Uid = localStorage.getItem("userId")
    await this.afs.collection('users').doc<UserModel>(Uid).get().subscribe(userDoc => {
      this.user = userDoc.data() as UserModel;
    })
  }



  openEditProfileComponent() {
    this.dialogService.open(ProfileComponent).onClose.pipe(take(1)).subscribe(data => {
      this._header.setcallBit = true;

      this.selectedItem = window.location.href.split('/')[4];
      this.selectedItem = this.toTitle(this.selectedItem);
    });
  }

  openChangePasswordComponent() {
    this.dialogService.open(ChangePasswordComponent).onClose.pipe(take(1)).subscribe(data => {
      this._header.setcallBit = true;
      this.selectedItem = (window.location.href).split('/')[4];
      this.selectedItem = this.toTitle(this.selectedItem);

    });
  }

  logOut() {
    return this.afAuth.signOut().then(() => {
      window.localStorage.setItem('alternateLogin1', JSON.stringify(false));
      window.localStorage.setItem('alternateLogin', JSON.stringify(false));
      window.localStorage.setItem('superAdmin', '');
      localStorage.removeItem('userId');

      localStorage.setItem("previoususerid", this.user.id);
      this.router.navigate(['']);
    });
  }


  onItemSelected() {
    this.menuService.onItemClick()
      .pipe(
        map(({ item: { title } }) => title),
      )
      .pipe(takeUntil(this.destroy$)).subscribe(title => {
        if (this.selectedItem == "Edit Profile" && this._header.getcallBit == true) {
          this._header.setcallBit = false;
          this.dialogService.open(ProfileComponent).onClose.pipe(take(1)).subscribe(data => {
            this._header.setcallBit = true;

            this.selectedItem = window.location.href.split('/')[4];
            this.selectedItem = this.toTitle(this.selectedItem);
          });
          return;

        }
        else if (this.selectedItem == 'Change Password' && this._header.getcallBit == true) {
          this._header.setcallBit = false;
          this.dialogService.open(ChangePasswordComponent).onClose.pipe(take(1)).subscribe(data => {
            this._header.setcallBit = true;
            this.selectedItem = (window.location.href).split('/')[4];
            this.selectedItem = this.toTitle(this.selectedItem);

          });
          return;
        }

        if (title === 'Log out') {
          debugger;
          return this.afAuth.signOut().then(() => {
            window.localStorage.setItem('alternateLogin1', JSON.stringify(false));
            window.localStorage.setItem('alternateLogin', JSON.stringify(false));
            window.localStorage.setItem('superAdmin', '');
            localStorage.removeItem('userId');
            localStorage.setItem("previoususerid", this.user.id);
            this.router.navigate(['']);
          });
        }
      });
  }

  toTitle(str) {
    return str.replace(/(^|\s)\S/g, (t) => { return t.toUpperCase() });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  languageChanged() {
    this.translate.use(this.selectedItemNgModel);
    localStorage.setItem('Lang', this.selectedItemNgModel);
    if (this.tempselecteditem.item) {
      var item = this.menu.filter(x => x.link == this.tempselecteditem.item.link);
      if (item)
        this.selectedItem = this.translate.instant(item[0].title);
    }

  }

  setLanguage() {
    const browserLang = this.translate.getBrowserLang();
    if (localStorage.getItem('Lang') != null) {
      this.translate.setDefaultLang(localStorage.getItem('Lang'));
      this.translate.use(localStorage.getItem('Lang'));
      this.selectedItemNgModel = localStorage.getItem('Lang');
    }
    else {
      this.translate.use('English');
      this.selectedItemNgModel = 'English';
    }
  }
}
