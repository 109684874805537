import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject } from '@angular/core';
import { Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient
  ) { }

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      'LOCALE': 'en',
      'Accept': 'application/json',
      'access-control-allow-origin': '*'
    };
    return new HttpHeaders(headersConfig);
  }

  // private formatErrors(error: any) {
  //   // if (error.status === 401) {
  //   //   this.router.navigateByUrl('/logout');
  //   // }
  //   return error && error.messages || throwError(error);
  //   //return error.error;
  // }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { headers: this.setHeaders(), params });
    // .pipe(catchError(error => this.formatErrors(error)));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}${path}`,
      JSON.stringify(body),
      { headers: this.setHeaders() }
    )
    // .pipe(catchError(error => this.formatErrors(error)));
  }

  post(path: string, body: Object = {}, options: any = {}): Observable<any> {
    if (options.responseType) {
      return this.http.post(
        `${environment.apiUrl}${path}`,
        JSON.stringify(body),
        { responseType: options.responseType, headers: this.setHeaders() }
      );
    } else {
      return this.http.post(
        `${environment.apiUrl}${path}`,
        JSON.stringify(body),
        { headers: this.setHeaders() }
      );
      // .catch(error => error);
    }
  }

  delete(path): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}${path}`,
      { headers: this.setHeaders() }
    )
  }

  extrnalPost(path: string, body: Object = {}, options: any = {}): Observable<any> {
    if (options.responseType) {
      return this.http.post(
        `${path}`,
        JSON.stringify(body),
        { responseType: options.responseType, headers: this.setHeaders() }
      );
    } else {
      return this.http.post(
        `${path}`,
        JSON.stringify(body),
        { headers: this.setHeaders() }
      );
      // .catch(error => error);
    }
  }
}
