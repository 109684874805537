import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class VideoProcessingService {


  maxWidth = 131; // Max width for the image
  maxHeight = 117;    // Max height for the image

  constructor(
    @Inject(DOCUMENT) private document: Document,private http:HttpClient
  ) { }


  public generateThumbnail(videoFile: Blob,imageName): Promise<any> {
    const video: HTMLVideoElement = this.document.createElement('video');
    const canvas: HTMLCanvasElement = this.document.createElement('canvas');
    const canvasCompressImage: HTMLCanvasElement = document.createElement('canvas');
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    const contextCompressImage: CanvasRenderingContext2D = canvasCompressImage.getContext('2d');
    return new Promise<any>((resolve, reject) => {
      canvas.addEventListener('error', reject);
      video.addEventListener('error', reject);
      video.addEventListener('loadedmetadata', () => {
        video.currentTime = Math.round(video.duration / 2);
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      });
      video.addEventListener('canplay', event => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        var img = new Image();
        img.src = canvas.toDataURL();
        img.onload = () => {
          let newSize = this.calculateAspectRatioFit(img?.width, img?.height);
          canvasCompressImage.width = newSize.width;
          canvasCompressImage.height = newSize.height;
          contextCompressImage.drawImage(img, 0, 0, img.width, img.height, 0, 0, newSize.width, newSize.height); // destination rectangle
          resolve(this.dataURLtoFile(canvasCompressImage.toDataURL(), 'thumbnail.jpg'));
        }
      });
      if (videoFile.type) {
        video.setAttribute('type', videoFile.type);
      }
      video.preload = 'auto';
      video.src = window.URL.createObjectURL(videoFile);
      video.load();
    });
  }

  compressImage(singlefile) {
    return new Promise<any>((resolve, reject) => {
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      var img = new Image();
      img.src = URL.createObjectURL(singlefile);
      img.onload = () => {
        let newSize = this.calculateAspectRatioFit(img?.width, img?.height);
        canvas.width = newSize.width;
        canvas.height = newSize.height;
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, newSize.width, newSize.height); // destination rectangle
        resolve(this.dataURLtoFile(canvas.toDataURL(), 'thumbnail.jpg'));
      }
    });
  }

  calculateAspectRatioFit(srcWidth, srcHeight): { width: number, height: number } {
    var ratio = Math.min(this.maxWidth / srcWidth, this.maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    const file = new File([blob], 'image.jpg', {type: blob.type});
    debugger;
    return new Promise((resolve) => {
      resolve(file);
    });
  }


  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

}
