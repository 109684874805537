import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { ClubModel } from 'app/model/clubModel';
import { UserModel } from 'app/model/user.model';
import { CommonserviceService } from 'app/commonservice/commonservice.service';
import { FirebaseService } from 'app/services/firebase.service';
import { of, Subscription } from 'rxjs';
import { take, map, exhaustMap } from 'rxjs/operators';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { TranslateService } from '@ngx-translate/core';
import { ProfileDataService } from 'app/services/profileData/profile-data-service.service';
import * as moment from 'moment';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  closeResult = '';
  resetemail: string;
  email: string = '';
  email1: string;
  password: string;
  message: string;
  user: any;
  club = {} as ClubModel;
  loader: boolean = false;
  loading: boolean = false;
  usernameCheck: boolean = false;
  usernameCheck1: string;
  CurrentClub = {} as ClubModel;
  clubidfromclubSub: Subscription;
  adminUser = {} as UserModel;
  errMsg: string;
  selectedItemNgModel: any;
  constructor(
    private apiService: ApiService,
    private firebaseservice: FirebaseService,
    private modalService: NgbModal,
    private router: Router,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    public toastrService: NbToastrService,
    private _CommonserviceService: CommonserviceService,
    public translate: TranslateService,
    private _profileDataService: ProfileDataService
  ) {
    this.translate.use('1');
    this.errMsg = '';
    this.resetemail = '';
    this.CurrentClub.id = this._CommonserviceService.getchangeClub;
    // this.clubidfromclubSub = this._CommonserviceService.getClub().subscribe((clicked) => {
    //   this.CurrentClub.id = clicked})
    if (JSON.parse(window.localStorage.getItem('alternateLogin1')) && this.CurrentClub.id) {

      this.afs.collection('clubs').doc(this.CurrentClub.id).get().subscribe(clubDoc => {
        this.CurrentClub = clubDoc.data() as ClubModel;
        if (JSON.parse(window.localStorage.getItem('superAdmin'))) {
          this.adminUser = JSON.parse(window.localStorage.getItem('superAdmin'));
          window.localStorage.setItem('loginMsg', this.adminUser.username + ' is logged in as: ' + this.CurrentClub.username);
        }
        this.continueLogin(this.CurrentClub.email, this.CurrentClub.pass, true);
      });
    }
    // });
    const browserLang = this.translate.getBrowserLang();
    if (localStorage.getItem('Lang') != null) {
      this.translate.setDefaultLang(localStorage.getItem('Lang'));
      this.translate.use(localStorage.getItem('Lang'));
      this.selectedItemNgModel = localStorage.getItem('Lang');
    }
    else {
      this.translate.use('English');
      localStorage.setItem('Lang', 'English');
    }


  }

  ngOnInit() { }

  ResetPassword() {
    console.log(this.resetemail);
    if (this.resetemail.replace(/ /g, '') == '') {
      this.errMsg = 'Enter Email';
      /*this.toastrService.danger('Enter Email','Forgot');*/
    }
    else if (!this.resetemail.match("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")) {
      this.errMsg = 'Enter Valid Email';
    } else {
      this.afs.collection('users', ref => ref.where('email', '==', this.resetemail.replace(/ /g, '')))
        .snapshotChanges().pipe(take(1),
          map(actions => actions.map(a => {
            const data = a.payload.doc.data() as UserModel;
            const id = a.payload.doc.id;
            return { id, ...data };
          })),
          exhaustMap((doc) => {
            debugger
            if (doc[0] && doc[0].email) {
              // let params: any = { email: this.resetemail }
              console.log(this.afAuth.sendPasswordResetEmail(this.resetemail));
              this.toastrService.success("Sent Email", "Reset");
              this.resetemail = '';
              this.modalService.dismissAll();
              // return this.apiService.get(`/sendChangePasswordEmail`, params)
            } else {
              this.errMsg = 'There is no user record corresponding to this email. The user may have been deleted.';
              return of(null);
            }
          })
        ).subscribe((result) => {
          debugger
          if (result == 'Mail Sent Successfully') {
            this.toastrService.success("Sent Email", "Reset");
            this.resetemail = '';
            this.modalService.dismissAll();
          } else {
            this.toastrService.danger(result, 'Error');
          }
        })
    }

  }
  openemailmodal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  getUserUsingUsername() {
    this.email = this.email.trim();
    if (this.email.match('^(?!.*\\.\\.)(?!.*\\.$)[^\\W][\\w.]{0,29}$')) {
      this.usernameCheck = true;
    } else {
      this.usernameCheck = false;
    }
  }

  login() {
    if (!this.email) {
      this.toastrService.danger('Enter Username', 'Login');
      return;
    } else if (!this.password) {
      this.toastrService.danger('Enter Password', 'Login');
      return
    }

    if (this.usernameCheck) {
      this.email = this.email.trim();
      this.afs.collection('users',
        ref => ref.where('username', '==', this.email))
        .snapshotChanges().pipe(take(1),
          map(actions => actions.map(a => {
            const data = a.payload.doc.data() as UserModel;
            const id = a.payload.doc.id;
            return { id, ...data };
          }))
        ).subscribe(doc => {
          if (doc[0]) {
            this.email1 = doc[0].email;
          }
          if (!this.email1) {
            this.toastrService.danger("Wrong Username!", "Login");
          }
          else if (!this.password) {
            this.toastrService.danger("Wrong Password!", "Login");
          }
          this.continueLogin(this.email1, this.password, false);
        })
    } else {
      this.email1 = this.email.trim();
      this.continueLogin(this.email1, this.password, false);
    }
  }
  continueLogin(email, pass, alternateLogin) {
    this.message = '';
    try {
      const result = this.afAuth.signInWithEmailAndPassword(email, pass);
      this.loader = true;
      result.then(resolve => {

        this.afAuth.authState.pipe(take(1)).subscribe(res => {

          if (res && res.uid) {
            this.afs.collection('users').doc<UserModel>(res.uid).get().subscribe(userDoc => {
              this.user = userDoc.data() as UserModel;
              this._profileDataService.setCurrentUser = this.user;
              this.club.id = this.user.clubID;
              this.afs.collection('clubs').doc(this.user.clubID).get().subscribe(clubDoc => {
                if (clubDoc.exists == true && (clubDoc.data() as ClubModel)?.isActive) {
                  localStorage.setItem("userId", res.uid);
                  this.club = clubDoc.data() as ClubModel;
                  this._profileDataService.setCurrentUserClub = this.club;
                  if (!alternateLogin) {
                    this.toastrService.success("Successfull", "Login");
                  }
                }
                else {
                  this.toastrService.danger('', 'Your Club has been deleted');
                  this.loader = false;
                  return;
                }
                if (pass == this.user.pass) {
                }
                else {
                  this.firebaseservice.updateuserpass(pass, this.user, this.club)
                }

                if (email.toLowerCase() == this.club.email.toLowerCase()) {
                  this.firebaseservice.updateclubpass(pass, this.user, this.club);
                }

                this.firebaseservice.updateAllMedia(this.club.id);
                if (this.club.isActive === true) {
                  if (!alternateLogin) {
                    this.afs.collection('users').doc(this.user.id).update({
                      lastLogin: moment().toDate()
                    });
                  }
                  this.loader = false;
                  if (this.user.channel == false && this.user.media == true && this.user.playlist == false) {
                    this.router.navigate(['pages/media']);
                  }
                  else
                    this.router.navigate(['/pages/channel']);
                }
                else {
                  if (!alternateLogin) {
                    this.loader = false;
                    this.loading = true;
                    // this.router.navigate(['']);
                    location.reload();
                    this.toastrService.danger('The club has been disabled by Super Admin', "Login");
                    this.afAuth.signOut().then(() => {
                      // this.router.navigate(['']);
                      location.reload();
                    });
                  } else {
                    this.loader = false;
                    this.loading = true;
                    if (this.user.channel == false && this.user.media == true && this.user.playlist == false) {
                      this.router.navigate(['/pages/media']);
                    }
                    else
                      // this.router.navigate(['/pages/channel']);
                      location.reload();

                  }
                }
              });


            });

          }
        });
      }).catch(reason => {
        this.loader = false;
        reason.message = reason.message.replace('The password is invalid or the user does not have a password.', 'Email or Password Incorrect');
        reason.message = reason.message.replace("identifier", "email");
        this.toastrService.danger(reason.message, "Login");
      });
    } catch (e) {
      //console.log(e);
    }
  }
  dialogclose(form: NgForm) {
    this.errMsg = '';
    this.modalService.dismissAll();
    this.resetemail = '';

  }
  setLang(langSelect) {
    this.translate.use(langSelect.value);
    localStorage.setItem('Lang', langSelect.value);
  }


  languageChanged() {
    this.translate.use(this.selectedItemNgModel);
    localStorage.setItem('Lang', this.selectedItemNgModel);
  }


}

