
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  apiUrl: "https://europe-west1-exposports-3618c.cloudfunctions.net",
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAmr7Bqt1owsQeH7jb_XmcIkIvV4QA7Bd8",
    authDomain: "exposports-3618c.firebaseapp.com",
    databaseURL: "https://exposports-3618c.firebaseio.com",
    projectId: "exposports-3618c",
    storageBucket: "exposports-3618c.appspot.com",
    messagingSenderId: "997700180295",
    appId: "1:997700180295:web:ed2dcd04614f3578",
    measurementId: "G-MQSZKSWDL1"
  }
};
