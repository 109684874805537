import { Injectable } from '@angular/core';
import { UserModel } from 'app/model/user.model';
import { ClubModel } from 'app/model/clubModel';

var user: UserModel;
var userClub: ClubModel
var userId: string;

@Injectable({
  providedIn: 'root'
})
export class ProfileDataService {

  constructor() { }

  public set setCurrentUser(userData: UserModel) {
    user = userData;
  }

  public set setCurrentUserClub(club: ClubModel) {
    delete club.pass;
    delete club.confirmPass;
    userClub = club;
  }

  public set setCurrentUserId(id: string) {
    userId = id;
  }

  public get getUser() {
    return user;
  }

  public get getUserClub() {
    return userClub;
  }

  public get getUserId() {
    return userId;
  }

}
