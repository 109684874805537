<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><img class="img_logo " style="max-width: 65px;"
        src="../../../assets/images/logo_expo-sport-media_exposportmedia.png"></a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>
<div style="margin: auto;" class="selected-Item"><h2>{{selectedItem}}</h2></div>

<div class="header-container" style="padding-right: 2%;">
  <div class="row">
    <div class="col-xl-12 col-xs-12 col-md-12 col-lg-12 row-container"
      style="display: inline-flex;justify-content: flex-end;">
      <div class="col-sm-1 col-xl-2 col-xs-6 col-md-2 col-lg-2 language-icon">
        <svg xmlns='http://www.w3.org/2000/svg' width='35px' height='35px' viewBox='0 0 512 512' style="float: right;margin-right: 2%;margin-top: 0.5%;">
          <title>ionicons-v5-l</title>
          <line class="langIcon" x1='48' y1='112' x2='336' y2='112' style='fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/>
          <line class="langIcon" x1='192' y1='64' x2='192' y2='112' style='fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/>
          <polyline class="langIcon" points='272 448 368 224 464 448' style='fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/>
          <line class="langIcon" x1='301.5' y1='384' x2='434.5' y2='384' style='fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/>
          <path class="langIcon" d='M281.3,112S257,206,199,277,80,384,80,384' style='fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/>
          <path class="langIcon" d='M256,336s-35-27-72-75-56-85-56-85' style='fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/>
        </svg>
      </div>
      <div class="col-sm-8 col-xl-7 col-xs-4 col-md-6 col-lg-6 btn-language lang-lg" style="padding: 0 10px 0 0;">
        <nb-select style="float: right;margin-right: 1%;padding: 0;width: 100%;" status="success"
          [(ngModel)]="selectedItemNgModel" [(selected)]="translate.currentLang" (ngModelChange)="languageChanged()">
          <nb-option value="English"><img src="../../../assets/images/united-states.svg"
              style="width: 22%;margin-right: 4%; margin-top: -2px">English</nb-option>
          <nb-option value="Deutsch"><img src="../../../assets/images/germany.svg"
              style="width: 22%;margin-right: 4%; margin-top: -2px">Deutsch</nb-option>
          <nb-option value="Francais"><img src="../../../assets/images/flag.svg"
              style="width: 22%;margin-right: 4%; margin-top: -2px">Francais</nb-option>
          <nb-option value="Nederlands"><img src="../../../assets/images/netherlands.svg"
              style="width: 22%;margin-right: 4%; margin-top: -2px">Nederlands</nb-option>
        </nb-select>
      </div>
      <div class="col-sm-8 col-xl-7 col-xs-4 col-md-6 col-lg-6 btn-language lang-sm" style="padding: 0 10px 0 0;">
        <nb-select style="float: right;margin-right: 1%;padding: 0;width: 100%;" status="success"
          [(ngModel)]="selectedItemNgModel" [(selected)]="translate.currentLang" (ngModelChange)="languageChanged()">
          <nb-option value="English"><img src="../../../assets/images/united-states.svg"
              style="width: 100%;margin-right: 4%; margin-top: -2px"><span class="lang-sm-span">English</span></nb-option>
          <nb-option value="Deutsch"><img src="../../../assets/images/germany.svg"
              style="width: 100%;margin-right: 4%; margin-top: -2px"><span class="lang-sm-span">Deutsch</span></nb-option>
          <nb-option value="Francais"><img src="../../../assets/images/flag.svg"
              style="width: 100%;margin-right: 4%; margin-top: -2px"><span class="lang-sm-span">Francais</span></nb-option>
          <nb-option value="Nederlands"><img src="../../../assets/images/netherlands.svg"
              style="width: 100%;margin-right: 4%; margin-top: -2px"><span class="lang-sm-span">Nederlands</span></nb-option>
        </nb-select>
      </div>
      <div class="col-sm-5 col-xl-4 col-xs-4 col-md-4 col-lg-4">
        <nb-actions size="medium" [class.right]="position === 'normal'" [class.left]="position === 'inverse'">
          <nb-action class="nbaction" *nbIsGranted="['view', 'user']">
            <!-- <nb-user [nbContextMenu]="userMenu" [name]="user?.username" [picture]="user?.picURL"
              nbContextMenuTrigger="click"></nb-user> -->
              
              <!-- <button [nbPopover]="templateRef" nbPopoverPlacement="bottom" style="background-color: transparent;
              border: none;">
                <nb-user  [name]="user?.username" [picture]="user?.picURL"></nb-user>
              </button>
              <ng-template #templateRef>
                <ol>
                  <li>Coffee</li>
                  <li>Tea</li>
                  <li>Milk</li>
                </ol>
              </ng-template> -->
              <div class="dropdown" style="float:right;">
                <button class="dropbtn"><nb-user  [name]="user?.username" [picture]="user?.picURL"></nb-user></button>
                <div class="dropdown-content" >
                <a (click) = "openEditProfileComponent()">Edit profile</a>
                <a (click) = "openChangePasswordComponent()" >Change password</a>
                <a (click) = "logOut()">Log out</a>
                </div>
              </div>
          </nb-action>
        </nb-actions>
      </div>
    </div>
  </div>
</div>
