import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, take, exhaustMap } from 'rxjs/operators';
import { UserModel } from './../../model/user.model';
import { of, Subscription } from 'rxjs';
import { ClubModel } from './../../model/clubModel';
import { TranslateService } from '@ngx-translate/core';
import { CommonserviceService } from './../../commonservice/commonservice.service';
import { NbToastrService } from '@nebular/theme';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseService } from './../../services/firebase.service';
import { ApiService } from './../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'rest-password',
  templateUrl: './rest-password.component.html',
  styleUrls: ['./rest-password.component.scss']
})
export class RestPasswordComponent implements OnInit {

  loginForm: FormGroup;
  msgCheck = false;
  linkMsg = '';
  actionCode = '';
  accountEmail = 'xyz@example.com';

  userId: string;
  newPassword: any = '';
  confirmPassword: any = '';
  closeResult = '';
  email: string;
  message: string;
  user: any;
  club = {} as ClubModel;
  loader: boolean = false;
  loading: boolean = false;
  usernameCheck: boolean = false;
  usernameCheck1: string;
  CurrentClub = {} as ClubModel;
  clubidfromclubSub: Subscription;
  adminUser = {} as UserModel;
  errMsg: string;
  selectedItemNgModel: any;
  public isDisabled = false;

  constructor(
    private apiService: ApiService,
    private firebaseservice: FirebaseService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    public toastrService: NbToastrService,
    private _CommonserviceService: CommonserviceService,
    public translate: TranslateService
  ) {
    this.errMsg = '';
    const browserLang = translate.getBrowserLang();
    if (localStorage.getItem('Lang') != null) {
      translate.setDefaultLang(localStorage.getItem('Lang'));
      translate.use(localStorage.getItem('Lang'));
      this.selectedItemNgModel = localStorage.getItem('Lang');
    }
    else {
      translate.use('English');
    }
  }

  ngOnInit() {
    this.formGroup();
    this.route.queryParams
      .subscribe(params => {
        if (params.mode && params.oobCode && params.apiKey) {
          this.actionCode = params.oobCode;

          this.afAuth.verifyPasswordResetCode(this.actionCode).then((email) => {
            this.accountEmail = email;
            if(!email ||email == undefined) {
              this.toastrService.danger('Error getting user against the corresponding email. Email might not exist')
            }
          }).catch((error) => {
            //this.spinner.hide();
            this.linkMsg = 'Your request to reset your password has expired or the link has already been used.';
            this.msgCheck = true;
          });

        }
        else {
          this.linkMsg = 'The selected page mode is invalid.';
          this.msgCheck = true;
        }
      });
  }

  formGroup() {
    this.loginForm = new FormGroup({
      newPassword: new FormControl(null, [Validators.required]),
      confirmPassword: new FormControl(null, [Validators.required])
    });
  }

  resetPassword() {
    const data = this.loginForm.value;
    const newData = data.newPassword
    if (data.confirmPassword.length == 0 || data.newPassword.length == 0) {
      this.toastrService.danger('Kindly Enter Your Password', 'Reset Password');
      return;
    } else if(data.confirmPassword.length < 8 || data.newPassword.length < 8) {
      this.toastrService.danger('Password needs to be minimum of 8 characters', 'Reset Password');
      return;
    } else if (data.newPassword != data.confirmPassword) {
      this.toastrService.danger('New and Confirm Password not matched', 'Reset Password');
      return;
    }
    else {
    this.loader = true;
    this.afAuth.confirmPasswordReset(this.actionCode, data.newPassword).then((resp) => {
      this.apiService.get(`/resetUserPassword?email=${this.accountEmail}&password=${data.newPassword}`
      ).subscribe((res) => {
        this.isDisabled = true;
        console.log('res: ', res);
        this.linkMsg = 'You can now sign in with your new password.';
        this.msgCheck = true;
        this.loader = false;
      });
    }).catch((error) => {

      this.linkMsg = 'Your request to reset your password has expired or the link has already been used.';
      this.msgCheck = true;
    });
  }
}

redirectTo(uri:string){
  this.router.navigateByUrl('/', {skipLocationChange: false}).then(()=>
  this.router.navigate([uri]));
  location.reload;
}

toLogin() {
  this.redirectTo('/auth/login');
  setTimeout(() => {
    location.reload();
  }, 120)
}

  setLang(langSelect) {
    this.translate.use(langSelect.value);
    localStorage.setItem('Lang', langSelect.value);
  }

  languageChanged() {
    this.translate.use(this.selectedItemNgModel);
    localStorage.setItem('Lang', this.selectedItemNgModel);
  }
}
