
<div style="position: fixed; right: 0;left: 0;z-index: 1030;">
    <div class="row" style="margin-top: 10px;">
      <div class="col-xl-12 col-xs-12 col-md-12 col-lg-12" style="display: inline-flex;justify-content: flex-end;">
        <div class="col-sm-9 col-xl-11 col-xs-8 col-md-9 col-lg-10 language-icon">
          <!--<img src="../../../assets/images/language.svg" style="width:35px;float: right;margin-right: 2%;margin-top: 0.5%;">-->
          <!-- <svg xmlns='http://www.w3.org/2000/svg' width='35px' height='35px' viewBox='0 0 512 512' style="float: right;margin-right: 2%;margin-top: 0.5%; color:blanchedalmond;">
            <title>ionicons-v5-l</title>
            <line class="langIcon" x1='48' y1='112' x2='336' y2='112' style='fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/>
            <line class="langIcon" x1='192' y1='64' x2='192' y2='112' style='fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/>
            <polyline class="langIcon" points='272 448 368 224 464 448' style='fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/>
            <line class="langIcon" x1='301.5' y1='384' x2='434.5' y2='384' style='fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/>
            <path class="langIcon" d='M281.3,112S257,206,199,277,80,384,80,384' style='fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/>
            <path class="langIcon" d='M256,336s-35-27-72-75-56-85-56-85' style='fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/>
          </svg> -->
        </div>
        <div class="col-sm-3 col-xl-1 col-xs-4 col-md-3 col-lg-2 btn-language" style="padding: 0 10px 0 0;">
          <nb-select style="float: right;margin-right: 1%;padding: 0;width: 100%;" status="success" [(ngModel)]="selectedItemNgModel"
                     [(selected)]="translate.currentLang" (ngModelChange)="languageChanged()">
            <nb-option value="English"><img src="../../../assets/images/united-states.svg"
                                            style="width: 22%;margin-right: 4%; margin-top: -2px">English</nb-option>
            <nb-option value="Deutsch"><img src="../../../assets/images/germany.svg"
                                            style="width: 22%;margin-right: 4%; margin-top: -2px">Deutsch</nb-option>
            <nb-option value="Francais"><img src="../../../assets/images/flag.svg"
                                             style="width: 22%;margin-right: 4%; margin-top: -2px">Francais</nb-option>
            <nb-option value="Nederlands"><img src="../../../assets/images/netherlands.svg"
                                               style="width: 22%;margin-right: 4%; margin-top: -2px">Nederlands</nb-option>
          </nb-select>
        </div>
      </div>
    </div>
    <!-- //<hr style="margin-top: 10px;"> -->
  </div>
  <div class="maindiv">
<div class="loginform" >
  <div class="responsive" style="max-width: 400px;margin: auto;">
  <img class="img_logo " src="../../../assets/images/logo_expo-sport-media_exposportmedia.png">
  <form #form="ngForm" aria-labelledby="title">

    <div class="form-control-group">
      <label class="label" for="input-email">{{'Username or Email address' | translate}}:</label>
      <input nbInput (input)="getUserUsingUsername()" fullWidth [(ngModel)]="email" required name="email" ngDefaultControl  id="input-email"
        pattern=".+@.+\..+" placeholder="{{'Username or Email address' | translate}}" autofocus>
    </div>

    <div class="form-control-group">
      <label class="label" for="input-password">{{'Password'| translate}}:</label>
      <input nbInput required fullWidth [(ngModel)]="password" name="password" type="password" ngDefaultControl  id="input-password"
        placeholder="{{'Password'| translate}}">
    <span class="forgotClass" style="cursor: pointer;" (click)="openemailmodal(content)">Forgot?</span>
    </div>

    <div style="font-weight: bold; color: red; text-align: center;">
      {{message}}<br>
    </div>

    <button nbButton fullWidth status="success" style="height: 55px; padding: 0%;" (click)="login()">
      {{'Log In'| translate}} &nbsp;&nbsp;<label class="sk-chase" style="margin-bottom: -2%;" *ngIf="loader">
        <span class="sk-chase-dot"></span>
        <span class="sk-chase-dot"></span>
        <span class="sk-chase-dot"></span>
        <span class="sk-chase-dot"></span>
        <span class="sk-chase-dot"></span>
        <span class="sk-chase-dot"></span>
      </label>
    </button>


  </form>
</div>
</div>
<div class="login-com" style="flex-grow: 1;">
  <div class="content">
  <h1 class="styles_title__DgCs1">{{ 'Digital Company' | translate }}</h1>
  <p class="styles_body__2pFRR">{{ 'Expo Sport Media is building a digital network together with the sports clubs.  A network of digital screens in canteens, where many people come to play or support.' | translate }}</p>
</div>

</div>

</div>

  <ng-template #content let-modal>

    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'Reset Password'| translate}}</h4>
    </div>
    <div class="modal-body">
      <form #myForm="ngForm">
        <div class="form-group">
          <label for="dateOfBirth">{{'Enter your email'| translate}}</label>
          <div class="input-group">
            <input id="dateOfBirth" class="form-control" placeholder="someone@xyz.com" name="dp" [(ngModel)]='resetemail'>
          </div><br>
          <p style="color: red" *ngIf="errMsg != ''">* {{errMsg}}</p>
        </div>
      </form>
    </div>
    <div class="modal-footer" style="display: inline-block;">


      <button nbButton style="float: left;" (click)="dialogclose(myForm)">{{'Close'| translate}}</button>
      <button nbButton style="float: right;" status="primary" (click)="ResetPassword()">{{'Submit'| translate}}</button>

    </div>

  </ng-template>









