import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Club',
    icon: 'home-outline',
    link: '/pages/club',
    home: true,
    pathMatch: 'full'
  },
  {
    title: 'Channels',
    icon: 'browser-outline',
    link: '/pages/channel',
    pathMatch: 'full'
  },
  {
    title: 'Playlists',
    icon: 'keypad-outline',
    link: '/pages/playlist',
    pathMatch: 'full'
  },
  {
    title: 'Media',
    icon: 'film-outline',
    link: '/pages/media',
    pathMatch: 'full'
  },
  {
    title: 'Network',
    icon: 'shuffle-2-outline',
    link: '/pages/network',
    pathMatch: 'full'
  },
  {
    title: 'Support',
    icon: 'question-mark-circle-outline',
    link: '/pages/support',
    pathMatch: 'full'
  },
  {
    title: 'Admin',
    icon: 'lock-outline',
    link: '/pages/admin',
    pathMatch: 'full'
  },
];
export const TEMP_MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Club',
    icon: 'home-outline',
    link: '/pages/club',
    home: true,
    pathMatch: 'full'
  },
  {
    title: 'Channels',
    icon: 'browser-outline',
    link: '/pages/channel',
    pathMatch: 'full'
  },
  {
    title: 'Playlists',
    icon: 'keypad-outline',
    link: '/pages/playlist',
    pathMatch: 'full'
  },
  {
    title: 'Media',
    icon: 'film-outline',
    link: '/pages/media',
    pathMatch: 'full'
  },
  {
    title: 'Network',
    icon: 'shuffle-2-outline',
    link: '/pages/network',
    pathMatch: 'full'
  },
  {
    title: 'Support',
    icon: 'question-mark-circle-outline',
    link: '/pages/support',
    pathMatch: 'full'
  },
  {
    title: 'Admin',
    icon: 'lock-outline',
    link: '/pages/admin',
    pathMatch: 'full'
  },
];
