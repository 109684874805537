import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ClubModel } from 'app/model/clubModel';
import { UserModel } from 'app/model/user.model';
import { Observable } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { AuthService } from '../Auth/auth.service';
import { ProfileDataService } from '../profileData/profile-data-service.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, public _profileDataService: ProfileDataService,
    public _authService: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin(state.url);
  }

  checkLogin(url: string): boolean {
    const Uid = localStorage.getItem('userId');
    if (Uid) {
      if (this._profileDataService.getUser == undefined) {
        this._authService.getUserUID().pipe(take(1)).subscribe(res => {
          this._profileDataService.setCurrentUserId = res.uid;
          this._authService.getUserProfile().pipe(take(1)).subscribe(userData => {
            var data = userData as UserModel;
            this._profileDataService.setCurrentUser = data;
            this._authService.getUserClub(res.uid).pipe(first()).subscribe((clubData:ClubModel)=>{
              if(clubData){
                this._profileDataService.setCurrentUserClub = clubData;
                this.router.navigate([url]);
                return true;
              }   
              else {
                this.router.navigate([url])

              }
            });
          });
        });
      }
      else {
        return true;
      }
    }
    else {
      this.router.navigate(['/auth'], { queryParams: { returnUrl: url } });
      return false;
    }
  }
}


