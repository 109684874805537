<div style="position: absolute;top: 0;right: 0;left: 0;z-index: 1030;">
  <div class="row" style="width: 100%; margin: 10px 0 0 0;">
    <div class="col-xl-3 col-lg-4 col d-flex justify-content-start align-self-center">
      <h2>{{"Reset Password" | translate}}</h2>
    </div>
    <div class="col-xl-7 col-lg-6 col d-flex justify-content-end align-self-center" style="height: 86px;">
      <img src="../../../assets/images/language.svg" style="width:35px;float: right;margin-right: 2%;margin-top: 0.5%;">
    </div>
    <div class="col-xl-2 col-lg-2 col d-flex justify-content-end align-self-center btn-language"
      style="padding: 0 10px 0 0;">
      <nb-select style="float: right;margin-right: 1%;padding: 0;width: 100%;" status="success"
        [(ngModel)]="selectedItemNgModel" [(selected)]="translate.currentLang" (ngModelChange)="languageChanged()">
        <nb-option value="English"><img src="../../../assets/images/united-states.svg"
            style="width: 22%;margin-right: 2%">English</nb-option>
        <nb-option value="Deutsch"><img src="../../../assets/images/germany.svg"
            style="width: 22%;;margin-right: 2%">Deutsch</nb-option>
        <nb-option value="Francais"><img src="../../../assets/images/flag.svg"
            style="width: 22%;;margin-right: 2%">Francais</nb-option>
        <nb-option value="Nederlands"><img src="../../../assets/images/netherlands.svg"
            style="width: 22%;;margin-right: 2%">Nederlands</nb-option>
      </nb-select>
    </div>
  </div>
  <hr style="margin-top: 10px;">
</div>

<div class="limiter" [nbSpinner]="loader" nbSpinnerStatus="danger">
  <div class="container-login100">
    <div class="wrap-login100">
      <div class="login100-pic js-tilt" data-tilt>
        <div class="logo-img-container">
          <div style="position: absolute;width: 100%;top: 19%;height: 70%;">
            <div style="width: 100%">
              <img src="../../../assets/images/logo_expo-sport-media_exposportmedia.png" alt="IMG" class="img-logo">
            </div>
          </div>
        </div>
      </div>

      <form class="login100-form validate-form" [formGroup]="loginForm" (ngSubmit)="resetPassword()">
        <div class="form-item-container">
          <div style="width: 100%;" class="form-sm">
            <div style="width: 100%;display: flex;justify-content: space-between">
              <img src="../../../assets/images/logo_expo-sport-media_exposportmedia.png" alt="IMG" class="img-logo">
            </div>
            <span class="login100-form-title app-name-sm" style="padding-top: 15px;">
              {{'Update Your Password' | translate}}
            </span>
          </div>
          <div style="display: flex">
            <span class="login100-form-title member-lg">
              {{'Update Your Password' | translate}}
            </span>
          </div>
          <div *ngIf="!msgCheck" style="padding: 5px; text-align: center;">Your Email: <strong>{{accountEmail}}</strong>
          </div>

          <div *ngIf="msgCheck" style="font-size: 18px; padding: 10px; text-align: center;">
            {{linkMsg}}
          </div>

          <div *ngIf="!msgCheck">
            <div class="wrap-input100 validate-input">
              <input class="input100" placeholder="{{'New Password' | translate}}" type="password"
                formControlName="newPassword">
              <span class="focus-input100"></span>
              <span class="symbol-input100">
                <i class="fa fa-lock" aria-hidden="true"></i>
              </span>
              <span class="help-block"
                *ngIf="!loginForm.get('newPassword').valid && loginForm.get('newPassword').touched">
                <span>{{'Please enter new password' | translate}}</span>
              </span>
            </div>

            <div class="wrap-input100 validate-input">
              <input class="input100" placeholder="{{'Confirm Password' | translate}}" type="password"
                formControlName="confirmPassword">
              <span class="focus-input100"></span>
              <span class="symbol-input100">
                <i class="fa fa-lock" aria-hidden="true"></i>
              </span>
              <span class="help-block"
                *ngIf="!loginForm.get('confirmPassword').valid && loginForm.get('confirmPassword').touched">
                <span>{{'Please confirm your new password' | translate}}</span>
              </span>
            </div>

            <div class="container-login100-form-btn">
              <button style="background-color: black;" class="login100-form-btn" type="submit">{{'Submit' | translate}}
              </button>
            </div>
          </div>
          <div class="conatiner-login100-form-btnA">
            <button style="background-color: red;" class="login100-form-btnA" (click)="toLogin()">Back to Login</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
