<nb-card style="width: 410px; height: 470px;" [nbSpinner]="changePasswordLoading" nbSpinnerStatus="danger"
  nbSpinnerSize="large" nbSpinnerMessage="" class="card-edit-Dialogue">
  <nb-card-header>{{'Change Password' | translate}}</nb-card-header>
  <nb-card-body>
    <form #form="ngForm" aria-labelledby="title">
      <div class="form-control-group">
        <label class="label" for="input-email">{{'Current Password' | translate}}:</label>
        <input nbInput fullWidth required type="password" maxlength="32" name="cpassword" [(ngModel)]="currentPassowrd"
          id="input-cpassword" placeholder="Password" autofocus>
      </div>
      <br>
      <div class="form-control-group">
        <label class="label" for="input-email">{{'New Password' | translate}}:</label>
        <input nbInput fullWidth required type="password" name="newpassword" [(ngModel)]="newPassword"
          id="input-cpassword" placeholder="New Password" maxlength="32" autofocus>
      </div>
      <br>
      <div class="form-control-group">
        <label class="label" for="input-password">{{'Confirm Password' | translate}}:</label>
        <input nbInput required fullWidth name="copassword" [(ngModel)]="confirmPassword" type="password"
          id="input-password" maxlength="32" placeholder="Confirm Password">
      </div>
      <br>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <div class="btn-lg-2">
      <button nbButton (click)="ref.close()">
        {{'Cancel' | translate}}
      </button>
      <button nbButton status="success" style="float: right;" (click)="updatePassword();">
        {{'Update' | translate}}
      </button>
    </div>
    <div class="btn-sm-2">
      <button nbButton (click)="ref.close()">
        <i style="cursor: pointer;" class="ion-close"></i>
      </button>
      <button nbButton status="success" style="float: right;" (click)="updatePassword();">
        <i style="cursor: pointer;" class="ion-checkmark"></i>
      </button>
    </div>
  </nb-card-footer>
</nb-card>
