import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ProfileDataService } from '../profileData/profile-data-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private afAuth: AngularFireAuth,
    private afs: AngularFirestore, private _priofileDataService: ProfileDataService) { }


  getUserUID() {
    return this.afAuth.authState;
  }

  getUserProfile() {
    return this.afs.collection('users').doc(this._priofileDataService.getUserId).valueChanges();
  }

  getUserClub(clubID){
    return this.afs.collection('clubs').doc(clubID).valueChanges();
  }
}
