import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { ClubModel } from 'app/model/clubModel';
import { UserModel } from 'app/model/user.model';
import { FirebaseService } from 'app/services/firebase.service';
import { NgForm }   from '@angular/forms';

@Component({
  selector: 'ngx-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  currentPassowrd: any = '';
  newPassword: any = '';
  confirmPassword: any = '';
  club: any;
  user: UserModel;
  changePasswordLoading: boolean = false;
  constructor(public translate: TranslateService,private afAuth: AngularFireAuth, public ref: NbDialogRef<ChangePasswordComponent>, private httpclient: HttpClient, public toastrService: NbToastrService, private firebaseservice: FirebaseService, private afs: AngularFirestore) { }

  ngOnInit() {
    this.getUserDetail();
  }

  getUserDetail() {
    this.changePasswordLoading = true;
    this.afAuth.authState.subscribe(res => {
      if (res && res.uid) {
        this.afs.collection('users').doc<UserModel>(res.uid).get().subscribe(userDoc => {
          this.user = userDoc.data() as UserModel;
          this.changePasswordLoading = false;
          this.getClubDetail(this.user);
        });
      }
    });
  }

  getClubDetail(user) {
    this.changePasswordLoading = true;
    this.afs.collection('clubs').doc<ClubModel>(user.clubID).get().subscribe(clubDoc => {
      this.club = clubDoc.data() as ClubModel;
      this.changePasswordLoading = false;
    });
  }

  updatePassword() {
    if (this.currentPassowrd.length == 0 || this.confirmPassword.length == 0 || this.newPassword.length == 0) {
      this.toastrService.danger('Empty Field', 'Error');
      return;
    }
    else if (this.confirmPassword.includes(' ') || this.newPassword.includes(' ')) {
      this.toastrService.danger('Password length must be greater than 8 characters without any spaces', 'Invalid Password Pattern');
      return;
    }
    else if (this.newPassword.length < 8 || this.confirmPassword.length < 8) {
      this.toastrService.danger('Password length must be greater than 8 characters', 'Invalid Password Pattern');
      return
    }
    else if (this.user.pass != this.currentPassowrd) {
      this.toastrService.danger('Invalid current password ', 'Error');
      return;
    }
    else if (this.newPassword != this.confirmPassword) {
      this.toastrService.danger('New and Confirm Password not matched', 'Error');
      return;
    }
    else {
      this.changePasswordLoading = true;
      this.httpclient
        .get(`/changePass?email=${this.user.email}&pass=${this.newPassword}`)
        .subscribe((res) => {
          this.firebaseservice.updateuserpass(this.newPassword, this.user, this.club);
          this.ref.close("");
          this.toastrService.success('Password Updated', "Password");
          this.changePasswordLoading = false;
          if (this.user.email === this.club.email) {
            this.firebaseservice.updateclubpass(this.newPassword, this.user, this.club);
          }
        });
    }

  }

  close() {
    this.ref.close("");
  }


}

